<template>
  <div class="warp">
    <van-form class="vanform">
      <van-field
        v-model="Forgetdata.phone"
        name="手机号"
        :left-icon="require('../../assets/img/sj.png')"
        placeholder="请输入您的手机号"
        clearable
      />
      <van-field
        v-model="Forgetdata.code"
        name="验证码"
        center
        :left-icon="require('../../assets/img/yzm.png')"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-button
            style="background-color: #f4f0e7"
            size="small"
            type="primary"
            color="#A78A48"
            plain
            :loading="yzmLoading"
            loading-type="spinner"
            loading-text="已发送"
            @click="getidencode"
          >
            发送验证码
          </van-button>
        </template>
      </van-field>
      <van-field
        v-model="Forgetdata.password"
        name="设置密码"
        :left-icon="require('../../assets/img/mm.png')"
        placeholder="密码为3-20位数字和字母组合"
      />
      <van-field
        v-model="Forgetdata.confirmpassword"
        name="密码"
        :left-icon="require('../../assets/img/mm.png')"
        placeholder="请确认新密码"
      />
    </van-form>
    <van-button
      style="margin-top: 50px; border-radius: 8px"
      block
      type="primary"
      size="large"
      color="linear-gradient(to right, #BCA675, #A78A48)"
      @click="confirmbtn"
      >确认</van-button
    >
  </div>
</template>

<script>
import { getSmsCode, updatePassword } from "@/api/login/login";
import { hex_md5 } from "../../utils/md5";
export default {
  name: "",
  data() {
    return {
      Forgetdata: {
        phone: "",
        code: "",
        password: "",
        confirmpassword: "",
      },
      lodForgetdata: {},
      yzmLoading: false,
      daoTime: 60,
    };
  },
  methods: {
    // 注册
    confirmbtn() {
      // 是否填写完整
      for (const key in this.Forgetdata) {
        if (!this.Forgetdata[key]) {
          this.$toast("请填写完整");
          return;
        }
      }
      // 二次密码是否一致
      if (this.Forgetdata.password !== this.Forgetdata.confirmpassword) {
        this.$toast("两次密码不一致");
        return;
      }
      // md5加密 密码
      this.lodForgetdata = { ...this.Forgetdata };
      this.lodForgetdata.password = hex_md5(this.lodForgetdata.password);
      updatePassword(this.lodForgetdata).then((res) => {
        if (res.data.code == 0) {
          this.$toast.success("修改成功");
          this.$router.push({
            path: "/login",
            params: {},
          });
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
    //获取验证码
    getidencode() {
      this.yzmLoading = true;
      // if (!/^1[3456789]\d{9}$/.test(this.Forgetdata.phone)) {
      //   this.$toast.fail("手机号格式不正确");
      //   this.yzmLoading = false;
      // } else {
      //   this.yzmLoading = true;
      // }
      getSmsCode(this.Forgetdata).then((res) => {
        if (res.data.code === 0) {
          this.timer = setInterval(() => {
            this.daoTime--;
            if (this.daoTime == 0) {
              this.yzmLoading = false;
              this.daoTime = 60;
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          this.$toast(res.data.msg);
          this.yzmLoading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.warp {
  width: 90%;
  margin: auto;
  margin-top: 50px;
}
.vanform {
  margin-bottom: 20px;
}
</style>
